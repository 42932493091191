import revive_payload_client_9bAiZenafb from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.33.0_@types+pg_2z2v6rkyyn3knfowxu657nx2zq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JTGJHGvllI from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.33.0_@types+pg_2z2v6rkyyn3knfowxu657nx2zq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yiskusQjeo from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.33.0_@types+pg_2z2v6rkyyn3knfowxu657nx2zq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_eBYv1YJwcr from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.33.0_@types+pg_2z2v6rkyyn3knfowxu657nx2zq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_X0jqhSt0G9 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.33.0_@types+pg_2z2v6rkyyn3knfowxu657nx2zq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_a1Z1EZHdnE from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.33.0_@types+pg_2z2v6rkyyn3knfowxu657nx2zq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_PJSkoh64vy from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.33.0_@types+pg_2z2v6rkyyn3knfowxu657nx2zq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_wEN5Yv9rm5 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.32.0_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/centre-supervisor-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_V8Y8RxT1OF from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.33.0_@types+pg_2z2v6rkyyn3knfowxu657nx2zq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_zshjRwWXJ6 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.32.0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/app/centre-supervisor-nuxt/.nuxt/primevue-plugin.mjs";
import plugin_client_irXqID22Xr from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@primevue+nuxt-module@4.2.5_patch_hash=y2bjtiada6cj3qvvllbuctyaka_@babel+parser@7.26.7_magica_u5r7wvlqawliz4uo63xm77kcpe/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5b5UgS1p75 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.32.0_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_FWzq6UEbQP from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.32.0_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_Yt9ztYy7xo from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.32.0_typesc_l67a3yjygojguh4ke5544n6qpq/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import trpc_IJvhwZlfLl from "/app/backend/plugins/trpc.ts";
import chartjs_1wC9lAM0Tp from "/app/centre-supervisor-nuxt/plugins/chartjs.ts";
import hookVueError_FUSNmNXceB from "/app/centre-supervisor-nuxt/plugins/hookVueError.ts";
export default [
  revive_payload_client_9bAiZenafb,
  unhead_JTGJHGvllI,
  router_yiskusQjeo,
  payload_client_eBYv1YJwcr,
  navigation_repaint_client_X0jqhSt0G9,
  check_outdated_build_client_a1Z1EZHdnE,
  chunk_reload_client_PJSkoh64vy,
  plugin_vue3_wEN5Yv9rm5,
  components_plugin_KR1HBZs4kY,
  prefetch_client_V8Y8RxT1OF,
  plugin_zshjRwWXJ6,
  primevue_plugin_egKpok8Auk,
  plugin_client_irXqID22Xr,
  switch_locale_path_ssr_5b5UgS1p75,
  i18n_FWzq6UEbQP,
  plugin_Yt9ztYy7xo,
  trpc_IJvhwZlfLl,
  chartjs_1wC9lAM0Tp,
  hookVueError_FUSNmNXceB
]